import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import defaultImage from '../../images/icon_as.png'

class SEO extends Component {
  static propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    url: PropTypes.string,
  }

  static defaultProps = {
    title: 'AS Consulting Group',
    description: 'Falta descripción',
    url: 'https://ascg.mx',
  }
  render() {
    const { props } = this
    const { location } = props
    return (
      <Helmet title={props.title}>
        <title>{props.title}</title>
        <meta name="description" content={props.description} />
        <meta name="image" content={props.image || defaultImage} />
        <meta
          name="google-site-verification"
          content="b_efzVAlmgEYLi9_zj17T9XkKEtI4q0AS4t20sNhT6Y"
        />

        <meta property="og:url" content={location && location.href} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={props.title} />
        <meta property="og:description" content={props.description} />
        <meta
          property="og:image"
          content={`https:${props.image}` || defaultImage}
        />
        {/* <meta property="fb:app_id" content={config.social.facebook} /> */}
        {/* <script
          type="text/javascript"
          async
          src="https://d335luupugsy2.cloudfront.net/js/loader-scripts/439d3f39-4509-4b46-9c04-a7037fc31c53-loader.js"
        ></script> */}
        <script
          SameSite="None; Secure"
          src="https://static.landbot.io/landbot-3/landbot-3.0.0.js"
        ></script>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="@ASConsultoresMx" />
        <meta
          name="twitter:site"
          content="https://mobile.twitter.com/ASConsultoresMx"
        />
        <meta name="twitter:title" content={props.title} />
        <meta name="twitter:description" content={props.description} />
        <meta name="twitter:image" content={`https:${props.image}`} />
      </Helmet>
    )
  }
}

export default SEO
